import React, { useEffect, useRef, useState } from 'react'
import ProductImg from './Assets/Img/productImg.jpg';
import Container from './Ui/Container'
import { Link, useLocation, useNavigate } from 'react-router-dom';

import press3 from './Assets/Img/PressPart/press2.jpg';
import press4 from './Assets/Img/PressPart/press3.webp';
import press1 from './Assets/Img/PressPart/press4.jpg';
import press5 from './Assets/Img/PressPart/press5.jpg';

import t1 from './Assets/Img/traub/t-1.jpg';
import t2 from './Assets/Img/traub/t-2.jpg';
import t3 from './Assets/Img/traub/t-4.webp';

import tr1 from './Assets/Img/threadRolling/tr-1.jpg';
import tr2 from './Assets/Img/threadRolling/tr-2.jpg';
import tr3 from './Assets/Img/threadRolling/tr-3.jpg';
import tr4 from './Assets/Img/threadRolling/tr-4.jpeg';

import clg1 from './Assets/Img/centerLessGranding/clg-1.jpg';
import clg2 from './Assets/Img/centerLessGranding/clg-2.jpg';
import clg3 from './Assets/Img/centerLessGranding/clg-3.jpg';

import adm1 from './Assets/Img/autoDebMachine/adm-1.jpg';
import adm2 from './Assets/Img/autoDebMachine/adm-2.webp';

import cnc1 from './Assets/Img/cnc_vmc/vmc-5.jpg';
import cnc2 from './Assets/Img/cnc_vmc/vmc-4.webp';
import cnc3 from './Assets/Img/cnc_vmc/vmc-3.jpg';
import cnc4 from './Assets/Img/cnc_vmc/vmc-2.webp';
import cnc5 from './Assets/Img/cnc_vmc/vmc-1.jpg';










import grub2 from './Assets/Img/grub2.jpg';
import grub4 from './Assets/Img/grub4.jpg';
import grub5 from './Assets/Img/grub5.jpg';

import cutter1 from './Assets/Img/cutterTool Resharpening/cutter1.webp';
import cutter2 from './Assets/Img/cutterTool Resharpening/cutter2.jpg';
import cutter3 from './Assets/Img/cutterTool Resharpening/cutter3.jpg';
import cutter4 from './Assets/Img/cutterTool Resharpening/cutter4.jpg';

import Button from './Ui/Button';

function Products() {
  const location = useLocation();
  const productId = location.state ? Number(location.state.productId) : null;
  const [showWork, setShowWork] = useState(productId ? productId : null);
  const showTabRef = useRef(null);


  useEffect(() => {
    if (productId && showTabRef.current) {
      showTabRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      window.scrollBy(0, -100);
    }
  }, [])
  
  useEffect(() => {
    if (showTabRef.current && showWork !== null) {
      const activeButton = showTabRef.current.querySelector(`[data-index="${showWork}"]`);
      if (activeButton) {
        const containerWidth = showTabRef.current.clientWidth;
        const activeButtonWidth = activeButton.offsetWidth;
        const activeButtonLeft = activeButton.offsetLeft;
        const scrollLeft = activeButtonLeft - (containerWidth - activeButtonWidth) / 2;
        showTabRef.current.scrollLeft = scrollLeft;
      }
    }
  }, [showWork]);


  const handleClick = (index) => {
    setShowWork(index);
  };

  const productsData = [
    {
      image: cnc1,
      alt: "cnc vmc job work",
      type: 6,
    },
    {
      image: cnc2,
      alt: "cnc vmc job work",
      type: 6,
    },
    {
      image: cnc3,
      alt: "cnc vmc job work",
      type: 6,
    },
    {
      image: cnc4,
      alt: "cnc vmc job work",
      type: 6,
    },
    {
      image: cnc5,
      alt: "cnc vmc job work",
      type: 6,
    },

    {
      image: adm1,
      alt: "Automactic deburing machine",
      type: 5,
    },
    {
      image: adm2,
      alt: "Automactic deburing machine",
      type: 5,
    },

    {
      image: clg1,
      alt: "Centerless Granding image",
      type: 4,
    },
    {
      image: clg2,
      alt: "Centerless Granding image",
      type: 4,
    },
    {
      image: clg3,
      alt: "Centerless Grandingimage",
      type: 4,
    },

    {
      image: tr1,
      alt: "Thread Rolling image",
      type: 3,
    },
    {
      image: tr2,
      alt: "Thread Rolling image",
      type: 3,
    },
    {
      image: tr3,
      alt: "Thread Rolling image",
      type: 3,
    },
    {
      image: tr4,
      alt: "Thread Rolling image",
      type: 3,
    },

    {
      image: t1,
      alt: "Traub Part image",
      type: 2,
    }, {
      image: t2,
      alt: "Traub Part image",
      type: 2,
    }, {
      image: t3,
      alt: "Traub Part image",
      type: 2,
    },
    {
      image: grub2,
      alt: "grub Screw image",
      type: 1,
    },
    {
      image: grub4,
      alt: "grub Screw image",
      type: 1,
    },
    {
      image: grub5,
      alt: "grub Screw image",
      type: 1,
    },
    {
      image: press3,
      alt: "Press Part image",
      type: 8,
    },
    {
      image: press4,
      alt: "Press Part image",
      type: 8,
    },
    {
      image: press5,
      alt: "Press Part image",
      type: 8,
    },
    {
      image: press1,
      alt: "Press Part image",
      type: 8,
    },

    {
      image: cutter1,
      alt: "Cutter Tool image",
      type: 7,
    },
    {
      image: cutter2,
      alt: "Cutter Tool image",
      type: 7,
    },
    {
      image: cutter3,
      alt: "Cutter Tool image",
      type: 7,
    },
    {
      image: cutter4,
      alt: "Cutter Tool image",
      type: 7,
    },

  ];

  return (
    <div className='' title='Our Products'>
      <img src={ProductImg} alt='product image for website' className='mainImg' />
      <Container className={"my-4 ov"} >
        <div className="work-button-container" >
          <div className="work-button nav-pills align-item-center d-flex justify-content-center" data-aos="zoom-out-left" data-aos-easing="linear" data-aos-duration="600"
            id="pills-tab" role="tablist" >
            <div className='buttons_mobileview' ref={showTabRef}>
              <button data-index={1} className={`workBtn ${showWork === 1 || showWork === null ? "active" : ''}`} type="button" onClick={() => handleClick(1)}> Grub Screw</button>
              <button data-index={2} className={`workBtn ${showWork === 2 ? "active" : ''}`} type="button" onClick={() => handleClick(2)}>Traub Parts</button>
              <button data-index={3} className={`workBtn ${showWork === 3 ? "active" : ''}`} type='button' onClick={() => handleClick(3)}> Thread Rolling Parts</button>
              <button data-index={4} className={`workBtn ${showWork === 4 ? "active" : ''}`} type='button' onClick={() => handleClick(4)}>Granding Parts</button>
              <button data-index={5} className={`workBtn ${showWork === 5 ? "active" : ''}`} type='button' onClick={() => handleClick(5)}>Deburing Machining</button>
              <button data-index={6} className={`workBtn ${showWork === 6 ? "active" : ''}`} type='button' onClick={() => handleClick(6)}>CNC & VMC Machining</button>
              <button data-index={7} className={`workBtn ${showWork === 7 ? "active" : ''}`} type='button' onClick={() => handleClick(7)}>Cutter Tool Resharpening</button>
              <button data-index={8} className={`workBtn ${showWork === 8 ? "active" : ''}`} type='button' onClick={() => handleClick(8)}> Press Parts</button>
            </div>
          </div>
        </div>
        <div className='mt-4 '>
          <div className='row '>
            {productsData && productsData.map((item, i) => {
              return (
                <React.Fragment key={i}>
                  {(showWork ? showWork : 1) === item.type && (
                    <div className='col-lg-4 col-md-6 col-sm-12  displayCenter  my-3' >
                      <div className='prodCard'>
                        <div>
                          <img src={item.image} about={item.alt} className='w-100 prd__img' />
                        </div>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              )
            })}
          </div>
        </div>
        <div className='displayCenter'>
          <Link to='/contact' title='get Quote' className='col d-flex displayCenter link'> <Button size={'large'}>Get Quote </Button></Link>
        </div>
      </Container>
    </div>
  )
}

export default Products