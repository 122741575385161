import React from 'react';
import Container from './Ui/Container';
import serviceImg from './Assets/Img/services.jpg'
import { GoGear } from "react-icons/go";

const servicesData = [
  {
    icon: <GoGear className="servicesIcon" />,
    title: 'Grub Screw Manufatering',
    content: 'Discover unparalleled precision with our Special Type Grub Screw service. Meticulously crafted using advanced techniques, these screws ensure optimal performance and reliability in a wide range of industrial applications, setting new standards in fastening solutions',
  },
  {
    icon: < GoGear className="servicesIcon" />,
    title: 'Traub Parts Manufactering',
    content: 'Our company specializes in manufacturing high-quality Traub parts. With precision engineering and a commitment to excellence, we deliver parts that meet the highest standards of performance and durability. Trust us for your Traub parts needs',
  },
  {
    icon: < GoGear className="servicesIcon" />,
    title: 'Thread Rolling Parts Manufactering ',
    content: 'We excel in the manufacturing of thread rolling parts, offering a wide range of solutions for various industries. Our parts are crafted with precision and expertise, ensuring optimal performance and durability for your applications. Trust us for your thread rolling parts needs',
  },
  {
    icon: < GoGear className="servicesIcon" />,
    title: 'All Type Of Granding services',
    content: 'Our company offers a comprehensive range of grinding services, catering to all types of materials and specifications. With state-of-the-art machinery and skilled technicians, we deliver precision grinding solutions that meet the highest quality standards. Trust us for all your grinding needs.',
  },
  {
    icon: < GoGear className="servicesIcon" />,
    title: 'Automated Deburing Machine',
    content: 'Our automated deburring machines are designed to efficiently remove burrs and sharp edges from metal components, ensuring smooth and safe surfaces. With advanced technology and precision engineering, our machines deliver consistent results, enhancing the quality and functionality of your parts. Trust us for automated deburring solutions.',
  },
  {
    icon: < GoGear className="servicesIcon" />,
    title: 'CNC & VMC Job Works',
    content: 'We provide CNC (Computer Numerical Control) and VMC (Vertical Machining Center) job works, offering precision machining services for a wide range of components. With state-of-the-art equipment and skilled operators, we deliver high-quality, accurate parts to meet your specifications. Trust us for your CNC and VMC machining needs.',
  },
  {
    icon: < GoGear className="servicesIcon" />,
    title: 'All Typer of Cutter tool resharpening',
    content: 'Our company specializes in the resharpening of all types of cutter tools, including end mills, drills, and inserts. With our precision grinding equipment and skilled technicians, we can restore your tools to like-new condition, ensuring optimal performance and longevity. Trust us for your cutter tool resharpening needs.',
  },
  {
    icon: < GoGear className="servicesIcon" />,
    title: 'Press Part Manufactering',
    content: 'We specialize in manufacturing press parts with precision and expertise. Our capabilities include producing a wide range of press components for various industries. With a focus on quality and efficiency, we deliver parts that meet your exact specifications and requirements. Trust us for your press part manufacturing needs.',
  },
];

const Services = () => (
  <div className='servicesBG' title='services'>
    <img src={serviceImg} className='mainImg' />
    <Container >
      <div className='row'>
        {servicesData.map((item, i) => {
          return (
            <React.Fragment key={i}>
              <div className='col-lg-4 col-md-6 col-sm-12  displayCenter'>
                <div className='services my-3 p-4'>
                  <div>{item.icon}</div>
                  <div className='text-center subHeading'>{item.title}</div>
                  <div className='text mt-4'>{item.content}</div>
                </div>
              </div>
            </React.Fragment>
          )
        })
        }
      </div>
    </Container>
  </div>
);

export default Services;
