import React from 'react'
import Container from './Ui/Container';
import company from '../Theme/Assets/Img/company.jpg';
import company1 from '../Theme/Assets/Img/company1.jpg';

import { HiBookOpen } from "react-icons/hi2";

function Company() {
  return (
    <div>
      <img src={company} alt='' className='w-100' />
      <Container>
        <div className="about-firstrow ">
          <hr />
          <h1 className="heading"> <HiBookOpen /> About Sai Shraddha Enginnering</h1>
          <div className="row">
            <div className="col-lg-5 displayCenter" >
              <img src={company1} className="img-fluid about-first-img p-0" alt="Resharpening Cutter" title='Resharpening Cutter' />
            </div>
            <div className="col-lg-7">
              <h6 className="mb-0 text">Welcome to Sai Shraddha Engineering, your trusted partner in precision engineering for the past 10 years.
                As industry leaders, we take pride in adhering to the highest standards of quality and precision in all our processes.
                At Sai Shraddha Engineering, we follow a meticulous approach, ensuring that each step of our manufacturing process complies
                with industry standards. Our commitment to quality control is unwavering, guaranteeing that every component leaving our facility
                meets or exceeds the stringent benchmarks set by the industry.
                With a decade of experience, we have fine-tuned our processes to seamlessly integrate traditional craftsmanship with cutting-edge technology.
                This dedication allows us to deliver solutions that not only meet but often surpass the expectations of our valued clients.
                As part of our commitment to excellence, Sai Shraddha Engineering remains at the forefront of industry advancements. We continually invest in training
                our team and adopting the latest technologies to offer you the most innovative and reliable precision engineering solutions available.
                Choose Sai Shraddha Engineering for a decade of expertise, a commitment to quality, and a promise to exceed your expectations.
                Join us on this journey of precision, innovation, and excellence, where every component is a testament to our dedication to the highest industry standards. </h6>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Company