import React from 'react'
import Container from '../Ui/Container';
import { BsFillTagsFill } from "react-icons/bs";
import { Link, useNavigate } from 'react-router-dom';
import { RiCustomerServiceFill } from "react-icons/ri";
import { FaArrowRightLong } from "react-icons/fa6";
import { FaPhoneAlt } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";



function Footer() {
    const navigate = useNavigate()
    const handleNavigate = (num) => {
        navigate('/product', { state: { productId: num } });
    }
    return (
        <>
            <footer className='footer_main'>
                <Container>
                    <div className="row text-light py-2 ">
                        <div className="col-sm-4 col-md-5 text-center displayCenter">
                            <h5 className='mb-0'>Need Tools In Bulk</h5>
                        </div>
                        <div className="col-sm-2 text-center">
                            <Link to='/services' title="Buy Tools in bulk quantity">
                                <div className="heading displayCenter">
                                    <div className='footerSvg displayCenter text-light fs-1'>
                                        <BsFillTagsFill />
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-sm-6 col-md-5 displayCenter  text-light">
                            <h5 className='mb-0 displayCenter'><span className='heading text-light'><RiCustomerServiceFill /></span> Our Executive will get in Touch</h5>
                        </div>
                    </div>
                </Container>
            </footer>
            <footer>
                <Container>
                    <div className="row compM">
                        <div className="col-sm-6 col-lg order-sm-3 order-lg-2 displayCenter flex-column">
                            <h3 className='primaryColor collection_h3'>Machine List</h3>
                            <ul className='collection_ul'>
                                <li className='text-center'>
                                    <Link to="/services" className='link' title="Tool And Cutter Machine">
                                        <span className='a_collection'><span className="mx-2 "><FaArrowRightLong /></span>Tool And Cutter Machine  </span>
                                    </Link >
                                </li>
                                <li className='text-center'>
                                    <Link to="/services" className='link' title="CNC Machine">
                                        <span className='a_collection'> <span className="mx-2 "><FaArrowRightLong /></span>CNC Machine  </span>
                                    </Link >
                                </li>
                                <li className='text-center'>
                                    <Link to="/services" className='link' title="VMC Machine">
                                        <span className='a_collection'> <span className="mx-2 "><FaArrowRightLong /></span> VMC Machine</span>
                                    </Link >
                                </li>
                                <li className='text-center'>
                                    <Link to="/services" className='link' title="Traub Machine">
                                        <span className='a_collection'> <span className="mx-2 "><FaArrowRightLong /></span>Traub Machine  </span>
                                    </Link >
                                </li>
                                <li className='text-center'>
                                    <Link to="/services" className='link' title=" Press  Machine">
                                        <span className='a_collection'> <span className="mx-2 "><FaArrowRightLong /></span> Press Machine </span>
                                    </Link >
                                </li>
                                <li className='text-center'>
                                    <Link to="/services" className='link' title="Automated Deburing Machine">
                                        <span className='a_collection'> <span className="mx-2 "><FaArrowRightLong /></span>Automated Deburing Machine  </span>
                                    </Link >
                                </li>
                                <li className='text-center'>
                                    <Link to="/services" className='link' title="Center Less Granding Machine">
                                        <span className='a_collection'> <span className="mx-2 "><FaArrowRightLong /></span>Centerless Granding Machine</span>
                                    </Link >
                                </li>
                                <li className='text-center'>
                                    <Link to="/services" className='link' title="Thread Rolling Machine">
                                        <span className='a_collection'> <span className="mx-2 "><FaArrowRightLong /></span>Thread Rolling Machine </span>
                                    </Link >
                                </li>
                                <li className='text-center'>
                                    <Link to="/services" className='link' title="Drill & Lathe  Machine">
                                        <span className='a_collection'> <span className="mx-2 "><FaArrowRightLong /></span>Drill & Lathe Machine </span>
                                    </Link >
                                </li>
                                <li className='text-center'>
                                    <Link to="/services" className='link' title="Slotting Machine">
                                        <span className='a_collection'> <span className="mx-2 "><FaArrowRightLong /></span>Slotting Machine </span>
                                    </Link >
                                </li>
                            </ul>
                        </div>

                        <div className="col-sm-6 col-lg order-sm-4 order-lg-3 displayCenter flex-column">
                            <h3 className='primaryColor collection_h3' >Collections</h3>
                            <ul className='collection_ul'>
                                <li className='text-center'>
                                    <span onClick={() => handleNavigate(1)} className='link' title="Grub Screw Manufactering">
                                        <span className='a_collection'><span className="mx-2 "><FaArrowRightLong /></span>Grub Screw Manufactering  </span>
                                    </span >
                                </li>
                                <li className='text-center'>
                                    <span onClick={() => handleNavigate(2)} className='link' title="Traub Parts Manufactering">
                                        <span className='a_collection'> <span className="mx-2 "><FaArrowRightLong /></span>Traub Parts Manufactering  </span>
                                    </span >
                                </li>
                                <li className='text-center'>
                                    <span onClick={() => handleNavigate(3)} className='link' title="Thread Rolling Parts Manufactering ">
                                        <span className='a_collection'> <span className="mx-2 "><FaArrowRightLong /></span>Thread Rolling Parts Manufactering  </span>
                                    </span >
                                </li>
                                <li className='text-center'>
                                    <span onClick={() => handleNavigate(4)} className='link' title="All Type Of Granding services">
                                        <span className='a_collection'> <span className="mx-2 "><FaArrowRightLong /></span>All Type Of Granding Services  </span>
                                    </span >
                                </li>
                                <li className='text-center'>
                                    <span onClick={() => handleNavigate(5)} className='link' title="Automated Deburing Machine">
                                        <span className='a_collection'> <span className="mx-2 "><FaArrowRightLong /></span>Automated Deburing Machine </span>
                                    </span >
                                </li>
                                <li className='text-center'>
                                    <span onClick={() => handleNavigate(6)} className='link' title="CNC & VMC Job Works ">
                                        <span className='a_collection'> <span className="mx-2 "><FaArrowRightLong /></span>CNC & VMC Job Works  </span>
                                    </span >
                                </li>
                                <li className='text-center'>
                                    <span onClick={() => handleNavigate(7)} className='link' title="All Typer of Cutter tool resharpening">
                                        <span className='a_collection'> <span className="mx-2 "><FaArrowRightLong /></span>All Typer Of Cutter Tool Resharpening</span>
                                    </span >
                                </li>
                                <li className='text-center'>
                                    <span onClick={() => handleNavigate(8)} className='link' title="Press Part Manufactering">
                                        <span className='a_collection'> <span className="mx-2 "><FaArrowRightLong /></span> Press Part Manufactering</span>
                                    </span >
                                </li>
                            </ul>
                        </div>
                        <div className="col-sm-6 col-lg add-ftr order-sm-2 order-lg-4  d-flex align-items-center justify-content-start flex-column">
                            <h3 className='primaryColor text-start collection_h3'>Contact us</h3>
                            <div>
                                <div className="row text-center d-flex align-items-center justify-content-start">
                                    <div className="col-2 pr-0 a_icon_center">
                                        <FaLocationDot className="ftr-contact-icon" />
                                    </div>
                                    <div className="col-10 text-start">
                                        <h6 className="mb-0">Plot No - 18/P/17 Behind SBI Bank Satpur MIDC Nasik-422007, Maharashtra</h6>
                                    </div>
                                </div>
                                <div className="row d-flex text-center align-items-start mt-2 mb-2">
                                    <div className='a_contact_div'>
                                        <div className="col-2 pr-0 a_icon_center">
                                            <FaPhoneAlt className="call Icon" />
                                        </div>
                                        <div className="col-10 text-start a_contact">
                                            <Link to="" className='link' title="Direct Whattsapp Chat" href="https://wa.me/917719832542?text=Hi">
                                                +91 9527191809
                                            </Link >
                                            <Link to="" className='link' title="Direct Whattsapp Chat" href="https://wa.me/917719832542?text=Hi">
                                                +91 9834630239
                                            </Link >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className='my-2' />
                    <div className='text-center'>
                        © Sai Shraddha Engineering 2023.
                    </div>
                </Container>
            </footer>
        </>
    )
}

export default Footer