import React, { useEffect, useState, useRef } from 'react';
import Container from './Ui/Container';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import Button from './Ui/Button';
import { MdOutlineMiscellaneousServices } from "react-icons/md";
import 'react-slideshow-image/dist/styles.css';
import { MdVerifiedUser } from "react-icons/md";

import clientLogo1 from '../Theme/Assets/Img/client1.gif';
import clientLogo2 from '../Theme/Assets/Img/client2.png';
import clientLogo3 from '../Theme/Assets/Img/client3.png';
import clientLogo4 from '../Theme/Assets/Img/bharatLogo.svg';



import { AiFillCustomerService } from "react-icons/ai";
import CountUpComp from './SubComponents/CountUp';
import { Link, useNavigate } from 'react-router-dom';

import press2 from './Assets/Img/PressPart/press5.jpg';
import press3 from './Assets/Img/PressPart/press4.jpg';
import press4 from './Assets/Img/PressPart/press2.jpg';

import grub2 from './Assets/Img/grub2.jpg';
import grub4 from './Assets/Img/grub4.jpg';

import cutter1 from './Assets/Img/cutterTool Resharpening/cutter1.webp';
import cutter2 from './Assets/Img/cutterTool Resharpening/cutter2.jpg';
import cutter3 from './Assets/Img/cutterTool Resharpening/cutter3.jpg';
import cutter4 from './Assets/Img/cutterTool Resharpening/cutter4.jpg';


import t1 from './Assets/Img/traub/t-1.jpg';
import t2 from './Assets/Img/traub/t-2.jpg';
import t3 from './Assets/Img/traub/t-4.webp';

import tr1 from './Assets/Img/threadRolling/tr-1.jpg';
import tr2 from './Assets/Img/threadRolling/tr-2.jpg';
import tr3 from './Assets/Img/threadRolling/tr-3.jpg';
import tr4 from './Assets/Img/threadRolling/tr-4.jpeg';

import clg1 from './Assets/Img/centerLessGranding/clg-1.jpg';
import clg2 from './Assets/Img/centerLessGranding/clg-2.jpg';
import clg3 from './Assets/Img/centerLessGranding/clg-3.jpg';

import adm1 from './Assets/Img/autoDebMachine/adm-1.jpg';
import adm2 from './Assets/Img/autoDebMachine/adm-2.webp';

import cnc1 from './Assets/Img/cnc_vmc/vmc-5.jpg';
import cnc2 from './Assets/Img/cnc_vmc/vmc-4.webp';
import cnc3 from './Assets/Img/cnc_vmc/vmc-3.jpg';
import cnc4 from './Assets/Img/cnc_vmc/vmc-2.webp';
import cnc5 from './Assets/Img/cnc_vmc/vmc-1.jpg';

import homeGrub from './Assets/Img/grub_screw_.jpg';

const divStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundSize: 'cover',
  backgroundPosition: "center",
  objectSize: "contain",
  objectFit:"cover",
  height: '100vh'
}
const slideImages = [
  {
    url: press3,
    heading: "Press Part Manufacturers ",
    caption: 'Dive into progress with our cutting-edge solutions, where advanced technology meets ingenuity, offering unparalleled  performance and shaping the future of your industry.',
    divBackground: "homeSliderDivBack",
    captionClassname: "homeSliderCap",
    headingClassname: "homeSliderHeading",
    ButtonName: "Our Products",
    navigate:"/products",
  },
  {
    url: cutter3,
    heading: "Cutter Tool Resharpening",
    caption: 'Experience excellence with our precision engineering solutions, where meticulous craftsmanship meets innovation.',
    divBackground: "homeSliderDivBack",
    captionClassname: "homeSliderCap",
    headingClassname: "homeSliderHeading",
    ButtonName: "Explore Quality",
    navigate:"/company/quality",
  },
  {
    url: homeGrub,
    heading: "Grub Screw Manufacturers ",
    caption: 'using our cutting-edge solutions, delivering precision and reliability in manufacturing to propel your success and growth seamlessly.',
    divBackground: "homeSliderDivBack",
    captionClassname: "homeSliderCap",
    headingClassname: "homeSliderHeading",
    ButtonName: "Explore services",  
    navigate:"/services",
  },
];


const images = [
  {
    url: clg1,
    name: 'Centerless Granding',
    button: 'View Product',
    link: '4',
  },
  {
    url: adm1,
    name: 'Deubring Part',
    button: 'View Product',
    link: '5',
  },
  {
    url: cnc1,
    name: 'CNC VMC Machining',
    button: 'View Product',
    link: '6',
  },
  {
    url: tr1,
    name: 'Thread Rolling',
    button: 'View Product',
    link: '3',
  },
  {
    url: cutter1,
    name: 'Cutter Resharpening',
    button: 'View Product',
    link: '7',
  },
  {
    url: clg2,
    name: 'Centerless Granding',
    button: 'View Product',
    link: '4',
  },
  {
    url: t1,
    name: 'Traub Part',
    button: 'View Product',
    link: '2',
  },
  {
    url: adm2,
    name: 'Debbaring',
    button: 'View Product',
    link: '5',
  },
  {
    url: press2,
    name: 'Press Part',
    button: 'View Product',
    link: '8',
  },
  {
    url: grub2,
    name: 'Grub Screw',
    button: 'View Product',
    link: '1',
  },
  {
    url: clg3,
    name: 'Centerless Granding',
    button: 'View Product',
    link: '4',
  },
  {
    url: cutter2,
    name: 'Cutter Resharpening',
    button: 'View Product',
    link: '7',
  },
  {
    url: tr2,
    name: 'Thread Rolling',
    button: 'View Product',
    link: '3',
  },
  {
    url: t2,
    name: 'Traub Part',
    button: 'View Product',
    link: '2',
  },
  {
    url: press3,
    name: 'Press Part',
    button: 'View Product',
    link: '8',
  },
  {
    url: cutter3,
    name: 'Cutter Resharpening',
    button: 'View Product',
    link: '7',
  },
  {
    url: cnc2,
    name: 'CNC VMC Machining',
    button: 'View Product',
    link: '',
  },
  {
    url: press4,
    name: 'Press Part',
    button: 'View Product',
    link: '8',
  },
  {
    url: cutter4,
    name: 'Cutter Resharpening',
    button: 'View Product',
    link: '7',
  },
  {
    url: tr3,
    name: 'Thread Rolling',
    button: 'View Product',
    link: '3',
  },
  {
    url: grub4,
    name: 'Grub Screw',
    button: 'View Product',
    link: '1',
  },

  {
    url: cnc3,
    name: 'CNC VMC Machining',
    button: 'View Product',
    link: '6',
  },
  {
    url: t3,
    name: 'Traub Part',
    button: 'View Product',
    link: '2',
  },
  {
    url: cutter1,
    name: 'Cutter Resharpening',
    button: 'View Product',
    link: ' ',
  },
  {
    url: tr4,
    name: 'Thread Rolling',
    button: 'View Product',
    link: '3',
  },
  {
    url: press2,
    name: 'Press Part',
    button: 'View Product',
    link: '8',
  },
  {
    url: grub2,
    name: 'Grub Screw',
    button: 'View Product',
    link: '1',
  },
  {
    url: cutter2,
    name: 'Cutter Resharpening',
    button: 'View Product',
    link: '7',
  },
  {
    url: press3,
    name: 'Press Part',
    button: 'View Product',
    link: '8',
  },
  {
    url: cutter3,
    name: 'Cutter Resharpening',
    button: 'View Product',
    link: '7',
  },
  {
    url: cnc4,
    name: 'CNC VMC Machining',
    button: 'View Product',
    link: '6',
  },
 
  {
    url: press4,
    name: 'Press  Part',
    button: 'View Product',
    link: '8',
  },
  {
    url: cutter4,
    name: 'Cutter Resharpening',
    button: 'View Product',
    link: '7',
  },
  {
    url: cnc5,
    name: 'CNC VMC Machining',
    button: 'View Product',
    link: '6',
  },
  {
    url: grub4,
    name: 'Grub Screw',
    button: 'View Product',
    link: '1',
  },
 

];

const OurClient = [
  {
    url: clientLogo1,
    title: "Nitesh Udyog Logo",
    alt:"Nitesh Udyog Logo"
  },
  {
    url: clientLogo2,
    title: "Shraddha Motor logo",
    alt:"Shraddha Motor logo"
  },
  {
    url: clientLogo3,
    title: "Jaynix Logo",
    alt:"Jaynix Logo"
  },
  {
    url: clientLogo4,
    title: "Bharat Precision Industry Logo",
    alt:"Bharat Precision Industry Logo"
  },

]
function Home() {
  const navigate = useNavigate();
  const handleNav = (no) => {
    navigate('/product', { state: { productId:no } });
  }

  const properties = {
    duration: 3000,
    transitionDuration: 500,
    infinite: true,
    indicators: false,
    arrows: true,
    pauseOnHover: false,

    get slides() {
      return images.map((image, index) => (
        <div key={index} className="each-slide  position-relative" title={image.name}>
          <img src={image.url} alt={image.name} className='  overflow-hidden col sliderBorder' />
          <div className='productSliderText col'>
            <div className='fs-4'>{image.name}</div>
            <span className='link' onClick={() => handleNav(image.link)}><Button size={'medium'} className={'my-2'} >{image.button}</Button></span>
          </div>
        </div>
      ));
    }, 

    containerStyle: {
      width: '100%',
      margin: '0',
      overflow: 'hidden',
      position: 'relative',
    },
  };




  return (
    <div className="slide-container" title='Sai Shraddha Enginnering '>
      <Slide>
        {slideImages.map((slideImage, index) => (
          <div key={index}>
            <div className='homeImgSlider' style={{ ...divStyle, 'backgroundImage': `url(${slideImage.url})` }}>
              <div className={`${slideImage.divBackground}`}>
                <h1 className={`${slideImage.headingClassname}`}>{slideImage.heading}</h1>
                <div className={`${slideImage.captionClassname}`} >{slideImage.caption}</div>
                <div className='d-flex align-item-center justify-content-center'> <Link className='link' to={slideImage.navigate}><Button size={'large'} className={'btnbackground'}>{slideImage.ButtonName}</Button></Link> </div>
              </div>
            </div>
          </div>
        ))}
      </Slide>
      <Container>
      <hr />

        <div className='row '>
          <div className='col-lg-4 col-md-6 col-sm-12 d-flex shadow' >  <img src={grub2} className='imgabout' alt='Grub screw image' title='Grub screw' /></div>
          <div className='col-lg-8 col-md-6 col-sm-12 text-left p-4 m-auto'>
            <div className='text-nowrap bg-white'><span className='heading'><MdOutlineMiscellaneousServices /> </span><span className='heading'>Service Info</span></div>
            <div className='descJustify bg-white' title='Services Information'>Welcome to Sai Shraddha Engineering's, where precision and innovation converge to redefine excellence in the manufacturing industry.
              Our comprehensive services cater to a diverse range of needs, ensuring that your machining requirements are met with utmost precision and efficiency.
              Our specialized processes and cutting-edge technology guarantee precision, durability, and reliability in every product.
              we take pride in our commitment to excellence. Whether you need customized components or precision tools, we are your trusted partner
              in driving innovation and success in the world of manufacturing.
            </div>
            <div title='Explore Services' className='my-4'><Link className='link' to='/services'><Button size={'large'}>Explore Our Services</Button></Link></div>
          </div>
        </div>

        <hr />

        <div className='row ' title='Our Experties'>
          <div className='col-lg-8 col-md-6 col-sm-12 text-left p-4  m-auto '>
            <div className='text-nowrap bg-white'><span className='heading'><MdVerifiedUser /> </span><span className='heading'>Our Experties</span></div>
            <div className='descJustify bg-white'>
              Welcome to Sai Shraddha Engineering's, where our expertise in precision engineering shines. Specializing in advanced grub screw manufacturing,
              press parts fabrication, and expert cutter resharpening, we deliver unparalleled quality. Our commitment to innovation, rigorous quality
              assurance, and a collaborative approach set us apart. Trust us for efficiency, reliability, and a seamless partnership in driving success in the
              world of manufacturing. Contact us today to experience the difference our expertise can make for your operations.
            </div>
            <div className='my-4'><Link to='/company/why-us' className='link'> <Button size={'large'}>Our Experties</Button> </Link></div>
          </div>
          <div className='col-lg-4 col-md-6 col-sm-12 p-4 d-flex shadow'>  <img src={[press2]} className='imgabout' alt='press part image' title='press part' /></div>
        </div>

        <hr />

        <div className='bg-white' title='Our Products' >
          <div className="cardSlider" style={properties.containerStyle}>
            <Slide {...properties} className='react-slideshow-wrapperr'>{properties.slides}</Slide>
          </div>
        </div>

        <hr />
        <CountUpComp />
        <Link to='/company/why-us' className='col d-flex displayCenter link'><Button className={'large'} >Explore More</Button></Link>
        <hr />

        <div className=' row '>
          <div className='heading text-center mb-4'><span className='heading'><AiFillCustomerService /></span> Our Clients</div>
          {OurClient && OurClient.map((item, i) => {
            return (
              <React.Fragment key={i}>
                <div className='col-lg-4 col-md-4 col-sm-12col-12 mb-3 clientLogo' title={item.title}>
                  <img src={item.url} alt={item.alt} className='w-100 cardShadow ' />
                </div>
              </React.Fragment>
            )
          })
          }
        </div>
        <hr />
      </Container>
    </div>
  )
}

export default Home;