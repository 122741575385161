import React from 'react'
import Container from './Ui/Container';
import { FaLocationDot } from "react-icons/fa6";
import { FaPhoneAlt } from "react-icons/fa";
import { IoMailUnreadOutline } from "react-icons/io5";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import ContactForm from './SubComponents/ContactForm'
import { FaWhatsapp } from "react-icons/fa";

function ContactUs() {
  return (
    <div className='contactBg my-4'>
      <Container>
        <div className='displayCenter flex-column  mb-4'>
          <h1 className='contactFont'>Let's Get Connected</h1>
          <h2 className='fs-2 my-4'>
            Get In Touch - Reach out to Sai Shraddha Engineering for Precision Solutions.
          </h2>
        </div>

        <div className='row contactRow '>
          <div className='col-lg-4 col-md-7  col-sm-12 contactCol1 '>
            <div className='p-4' >
              <h4 className='heading displayCenter text-white'>Get Quote</h4>
              <div className=' text text-white'> Contact Sai Shraddha Engineering: Your Partner for Precision Manufacturing Solutions. </div>
              <div className='displayCenter justify-content-start gap-4 mt-4'>
                <div><FaLocationDot className="text-light fs-3" /></div>
                <div className='text text-white'>
                Plot No - 18/P/17 Behind SBI Bank Satpur MIDC Nasik-422007, Maharashtra
                </div>
              </div>
              <div className='displayCenter justify-content-start gap-4 mt-2'>
                <div><FaPhoneAlt className="text-light fs-3" /></div>
                <div className='text text-white'>
                  +91 9527191809
                </div>
                <div className='text text-white'>
                  +91 9834630239
                </div>
              </div>
              <div className='displayCenter justify-content-start gap-4 mt-2'>
                <div><IoMailUnreadOutline className="text-light fs-3" /></div>
                <div className='text text-white'>
                saishraddha2014.nsk@gmail.com
                </div>
              </div>
              <div className='displayCenter justify-content-between  gap-2 mt-2 contactDesignMain'>
                <div className='displayCenter gap-3 '>
                  <div><FaFacebook className="text-light fs-3" /></div>
                  <div><FaInstagram className="text-light fs-3" /></div>
                  <div><FaLinkedin className="text-light fs-3" /></div>
                  <div><FaWhatsapp className="text-light fs-3" /></div>

                  
                </div>
                <div className='contactDesign'>
                  <div className="contactDesignsmallcircle"></div>

                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-8 col-md-7 col-sm-12 my-3'>
            <ContactForm />
          </div>
        </div>
      </Container>
    </div>

  )
}

export default ContactUs