import React from 'react';
import BlogImg from './Assets/Img/blog.jpg'
import Container from './Ui/Container';
import { FaArrowRightLong } from "react-icons/fa6";
import { Link } from 'react-router-dom';


function Blog() {

  const servicesData = [

    {
      title: 'Advanced Cutter Resharpening ',
      imgSrc: BlogImg,
      date:"2 jan 2023",
      content: 'Cutting-Edge Solutions: The Impact of Advanced Cutter Resharpening on Manufacturing Efficiency...',
      navigatePath:"blog/cutter-resharpening"
    },
    {
      title: 'Special Type Grub Screws',
      date:"16 jan 2023",
      imgSrc:BlogImg,
      content: 'The Evolution of Industrial Fastening: A Deep Dive into Special Type Grub Screws...',
      navigatePath:"blog/grub-screws"
    },
  ]
  return (
    <div className='servicesBG' title='our Blogs/'>
      <img src={BlogImg} alt='' className='w-100' />
      <Container >
        <div>
          <div className='row'>
            {servicesData.map((item, i) => {
              return (
                <React.Fragment key={i}>
                  <div className='col-lg-4 col-md-6 col-sm-12  displayCenter'>
                    <div className='blog my-3 p-4'>
                    <img src={item.imgSrc} alt='' className='w-100' />
                    <div className='mt-3 linkColor'>{item.date} <FaArrowRightLong /></div>
                      <div className='text-center subHeading'>{item.title}</div>
                      <div className='text mt-4'>{item.content}</div>
                    <Link to={`/${item.navigatePath}`}>
                    <div className='linkColor'>Read More...</div>
                    </Link>
                    </div>
                  </div>
                </React.Fragment>
              )
            })
            }
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Blog